import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ProvideAuth from "./helpers/firebaseAuthContext";
import ProtectedRoute from "./helpers/ProtectedRoute";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import PegawaiPage from "./pages/Pegawai";
import SettingsPage from "./pages/Settings";

export default function Routers() {
  return (
    <ProvideAuth>
      <Router>
        <div>
          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/users">Users</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
            </ul>
          </nav> */}
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <ProtectedRoute path="/pegawai" redirectTo="/login">
              <PegawaiPage />
            </ProtectedRoute>
            <ProtectedRoute path="/settings" redirectTo="/login">
              <SettingsPage />
            </ProtectedRoute>
            <ProtectedRoute path="/" redirectTo="/login">
              <HomePage />
            </ProtectedRoute>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}
