import React, { useState } from "react";
import { view } from "@risingstack/react-easy-state";
import authStore from "../stores/authStore";
import { Redirect } from "react-router-dom";

function LoginPage() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    authStore.login(userName, password);
  };

  if (authStore.auth != null) {
    return <Redirect to={"/"} />;
  }

  return (
    <div className="container">
      <div className={authStore.loading ? "loader-block" : ""} />
      <div className="center">
        <div className="row login-row">
          <div className="col s6 offset-s3">
            <div className="card ">
              <form onSubmit={handleSubmit}>
                <div className="card-content">
                  <span className="card-title">Login</span>
                </div>
                <div class="divider"></div>
                <div className="card-content">
                  <div className="row">
                    <div className="input-field col s12">
                      <i className="material-icons prefix">local_phone</i>
                      <input
                        id="userName"
                        name="userName"
                        type="text"
                        className="validate"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <label htmlFor="userName" className="active">
                        Phone
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <i className="material-icons prefix">password</i>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label htmlFor="password" className="active">
                        Password
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <span className="red-text">{authStore.errorLogin}</span>
                  </div>
                </div>
                <div className="card-action">
                  <button
                    type="submit"
                    className="waves-effect waves-light btn-large w100"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default view(LoginPage);
