import "./App.css";
import Routers from "./Routers";

export default function App() {
  return (
    <div className="root-container">
      <Routers />
    </div>
  );
}
