import React, { useEffect } from "react";
import Navigation from "../components/Navigation";
import { view } from "@risingstack/react-easy-state";
import personStore from "../stores/personStore";
import ImageFirebase from "../components/ImageFirebase";

function PegawaiPage() {
  useEffect(function () {
    personStore.getFaceCount();
    personStore.getFaceList();
  }, []);

  const listItems = personStore.data.map((doc) => (
    <div className="col s3" key={doc._id}>
      <div className="card card-list-person">
        <div className="card-image">
          <ImageFirebase path={`person/slt/face/${doc._id}/1.jpg`} />
          <span className="card-title">{doc.name}</span>
        </div>
        <div className="card-content card-content-list-person">
          <div>
            <small>
              <b>{doc.name}</b>
            </small>
          </div>
          <div>
            <small>
              <b>{doc.no}</b>
            </small>
          </div>
          <div>
            <small>
              <b>{doc.mobile.replace("+628", "08")}</b>
            </small>
          </div>
          <div>
            <small>{doc.mainOrgName}</small>
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div>
      <div className={personStore.loading ? "loader-block" : ""} />
      <Navigation />
      <div className="container">
        <div className="row">
          <div className="col s12">
            <div className="header">
              <h5>{`Sampel Wajah Pegawai - Lisensi Wajah Terpakai ${personStore.faceCount}/20000 Total Lisensi`}</h5>
            </div>
            <div className="content-pegawai">
              <div className="row">{listItems}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default view(PegawaiPage);
