import React, { createContext, useState, useEffect } from "react";
import { fbAuth } from "../api/firebase";
import authStore from "../stores/authStore";
export const AuthContext = createContext({ userPresent: false, user: null });
export default function FirebaseAuthContext(props) {
  let [state, changeState] = useState({
    userDataPresent: false,
    user: null,
    listener: null,
  });

  useEffect(() => {
    if (state.listener == null) {
      changeState({
        ...state,
        listener: fbAuth.onAuthStateChanged((user) => {
          authStore.setAuth(user);
          if (user) {
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: user,
            }));
          } else {
            changeState((oldState) => ({
              ...oldState,
              userDataPresent: true,
              user: null,
            }));
          }
        }),
      });
    }
    return () => {
      if (state.listener) state.listener();
    };
  }, []);

  return (
    <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
  );
}
