import React from "react";
import { store } from "@risingstack/react-easy-state";
import { fbAuth } from "../api/firebase";

const authStore = store({
  auth: null,
  loading: false,
  setAuth: function (newAuth) {
    authStore.auth = newAuth;
  },
  errorLogin: "",
  login: async function (inputUserName, inputPassword) {
    let mobile = inputUserName;
    let password = inputPassword;

    if (!mobile) {
      authStore.errorLogin = "No Handphone minimal 10 angka";
      return;
    }
    if (!password) {
      authStore.errorLogin = "password minimal 6 karakter";
      return;
    }
    mobile = mobile.trim();
    password = password.trim();

    if (password.length < 6) {
      authStore.errorLogin = "password minimal 6 karakter";
      return;
    }
    if (mobile.length < 10) {
      authStore.errorLogin = "No Handphone minimal 10 angka";
      return;
    }

    let emailPhone = `${mobile}`;
    if (emailPhone.startsWith("08")) {
      emailPhone = emailPhone.replace("08", "+628");
    }
    emailPhone = `m${emailPhone.replace("+", "")}@eabsen.com`;

    authStore.errorLogin = "";
    authStore.loading = true;

    try {
      await fbAuth.signInWithEmailAndPassword(emailPhone, password);
    } catch (err) {
      let errMsg = err.message || err.code;

      if (err.code === "auth/user-not-found") {
        errMsg = `Nomor ${mobile} belum terdaftar`;
      }
      if (err.code === "auth/wrong-password") {
        errMsg = "Password salah";
      }

      authStore.errorLogin = errMsg;
    }
    authStore.loading = false;
  },
  logout: async function () {
    authStore.loading = true;
    authStore.errorLogin = "";
    try {
      await fbAuth.signOut();
    } catch (err) {
      let errMsg = err.message || err.code;
      authStore.errorLogin = errMsg;
    }

    setTimeout(() => {
      window.location.reload();
    }, 2000);

    // setTimeout(() => {
    //   authStore.loading = false;
    // }, 2000);
  },
});
export default authStore;
