import firebase from "firebase/app";

import "firebase/performance";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBfDaZSD-SObbJhXpXA31EGw_xGa_u7KZU",
  authDomain: "eabsen-cloud.firebaseapp.com",
  databaseURL: "https://eabsen-cloud.firebaseio.com",
  projectId: "eabsen-cloud",
  storageBucket: "eabsen-cloud.appspot.com",
  messagingSenderId: "364298421849",
  appId: "1:364298421849:web:a0f8268ca06d15cb",
};

const fbApp = firebase.initializeApp(firebaseConfig);
// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();
// As httpOnly cookies are to be used, do not persist any state client side.
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
fbApp.auth().languageCode = "id";

const _googleAuthProvider = new firebase.auth.GoogleAuthProvider();
_googleAuthProvider.addScope("https://www.googleapis.com/auth/userinfo.email");

const _newFirebaseRecaptchaVerifier = function () {
  return new firebase.auth.RecaptchaVerifier("recaptchaWidget", {
    size: "invisible",
  });
};

export const newFirebaseRecaptchaVerifier = _newFirebaseRecaptchaVerifier;
export const googleAuthProvider = _googleAuthProvider;
export const fbPerf = perf;
export const fbAuth = fbApp.auth();
export const fbStorage = fbApp.storage();
export const fbFunctions = fbApp.functions();
export const fbDbTimestamp = firebase.database.ServerValue.TIMESTAMP;
export const fbDbFirestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp();
