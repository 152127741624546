import "whatwg-fetch";
import { fbAuth } from "./firebase";
const baseApi = "https://io.mypegawai.com";

const fetchApiReq = function (pathUrl, options, resolve, reject) {
  let baseApiUrl = baseApi;

  let httpStatus;
  window
    .fetch(`${baseApiUrl}${pathUrl}`, options)
    .then((resp) => {
      httpStatus = resp.status;

      if (httpStatus === 401) {
        fbAuth.signOut();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

      if (httpStatus === 403) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }

      if (httpStatus < 200 || httpStatus > 299) {
        return resp.text();
      }

      return resp.json();
    })
    .then((resp) => {
      if (httpStatus === 404) {
        return reject(`api ${pathUrl} not found`);
      }
      if (httpStatus < 200 || httpStatus > 299) {
        return reject(resp);
      }

      return resolve(resp);
    })
    .catch(() => {
      return reject(`Internet offline, mohon periksa internet anda`);
    });
};

const fetchApi = function (pathUrl, options) {
  let parseOptions = options;
  if (!parseOptions) {
    parseOptions = {};
  }

  parseOptions.credentials = "include";

  if (!parseOptions.headers) {
    parseOptions.headers = {};
  }

  if (parseOptions.method === "POST") {
    if (!parseOptions.multipart) {
      parseOptions.headers["Content-Type"] = "application/json";
      if (!options.body) {
        parseOptions.body = JSON.stringify({});
      } else {
        parseOptions.body = JSON.stringify(options.body);
      }
    } else {
      delete parseOptions.multipart;
    }
  }

  return new Promise((resolve, reject) => {
    return fetchApiReq(pathUrl, parseOptions, resolve, reject);
  });
};

export default fetchApi;
