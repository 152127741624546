import React, { useState, useEffect } from "react";
import { fbStorage } from "../api/firebase";

function ImageFirebase({ path }) {
  const [urlImage, setUrlImage] = useState("/images/no-image.png");

  useEffect(function () {
    const fileRef = fbStorage.ref(path);
    fileRef
      .getDownloadURL()
      .then((url) => {
        setUrlImage(url);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <img className="avatar-list" src={urlImage} />
    </div>
  );
}

export default ImageFirebase;
