import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import { view } from "@risingstack/react-easy-state";
import personStore from "../stores/personStore";

function SettingsPage() {
  useEffect(function () {
    personStore.getFaceCount();
  }, []);

  return (
    <div>
      <div className={personStore.loading ? "loader-block" : ""} />
      <Navigation />
      <div className="container">
        <div className="row">
          <div className="col s12">
            <div className="header">
              <h5>Settings</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default view(SettingsPage);
