import React from "react";
import { Link } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import authStore from "../stores/authStore";

function Navigation() {
  return (
    <div>
      <div className={authStore.loading ? "loader-block" : ""} />
      <nav className="light-blue">
        <div className="nav-wrapper">
          <ul className="left ">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/pegawai">Pegawai</Link>
            </li>
            <li>
              <Link to="/settings">Settings</Link>
            </li>
          </ul>
          <ul className="right">
            <li>
              <button
                className="btn-logout waves-effect waves-teal btn-flat"
                type="button"
                onClick={authStore.logout}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default view(Navigation);
