import React from "react";
import Navigation from "../components/Navigation";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div>
      <Navigation />

      <div className="container">
        <div className="col s6 offset-s3">
          <div>
            <Link to="/pegawai">
              <img
                className="image-home"
                src={"/images/face-recognation.png"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
