import React from "react";
import { store } from "@risingstack/react-easy-state";
import {
  restGetPersonFaceCountCache,
  restGetPersonFaceListCache,
} from "../api/person";

const personStore = store({
  faceCount: 0,
  data: [],
  loading: false,
  errorMsg: "",
  getFaceList: async function () {
    personStore.loading = true;
    personStore.errorMsg = "";
    try {
      const resp = await restGetPersonFaceListCache({ companyId: "slt" });
      if (resp && resp.status == 200) {
        personStore.data = resp.data;
      }
      personStore.loading = false;
    } catch (err) {
      personStore.errorMsg = err;
      personStore.loading = false;
      return;
    }
  },
  getFaceCount: async function () {
    personStore.loading = true;
    personStore.errorMsg = "";
    try {
      const resp = await restGetPersonFaceCountCache({ companyId: "slt" });
      if (resp && resp.status == 200) {
        personStore.faceCount = resp.data;
      }
      personStore.loading = false;
    } catch (err) {
      personStore.errorMsg = err;
      personStore.loading = false;
      return;
    }
  },
});

export default personStore;
