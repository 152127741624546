import fetchApi from "./fetchApi";

// GET
export function restGetPersonFaceCountCache({ companyId }) {
  let path = `/api/v3`;
  path += `/person/${companyId}/person-face-count.js`;
  return fetchApi(path, {
    method: "GET",
    // authorization: 'JethroAdminEabsen2019',
  });
}

export function restGetPersonFaceListCache({ companyId }) {
  let path = `/api/v3`;
  path += `/person/${companyId}/person-face-list.js`;
  return fetchApi(path, {
    method: "GET",
    // authorization: 'JethroAdminEabsen2019',
  });
}
